import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuBar from '../components/Home/MenuBar';
import Footer from '../components/Home/Footer';
import CreditCard from './Home/v2/order/CreditCard';
import isMobile from '../services/isMobile';
import OrderSummary from './Home/v2/OrderSummary';

import { colors, margins, Subtitle2, OrderSummaryText, mobileThresholdPixels, ErrorText, Content, Container, Button }
  from './Home/v2/StyledComponents';

import backgroundImg from '../assets/order/orderSummaryHigh.jpg';

import { isCardTypeValid, isCardNumberValid, errorMessages } from '../services/mangopay';

const Background = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  padding: ${margins.l};

  background-size: cover;
  background-color: ${colors.white};
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: ${mobileThresholdPixels}) {
    min-height: unset;
    width: 100%;
    padding: ${margins.l} 0px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => !props.flexStart && 'align-items: center'};
  ${props => props.margin && `margin: ${props.margin}`};
  font-family: Roboto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.center && 'align-items: center'};
`;

const StyledSubTitle2 = styled(Subtitle2)`
  text-align: left;
  margin-top: ${margins.l};
`;

const TextContainer = styled(OrderSummaryText)`
  ${props => !props.noMargin && `margin-top: ${margins.s}`};
  ${props => props.alignRight && 'text-align: right'};
  ${props => props.bold && `
    letter-spacing: 1px;
    font-weight: bold;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const PaymentContainer = styled.div`
  width: calc(590px - 2 * ${margins.l});
  background-color: ${colors.white};
  padding: 0px ${margins.l} ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: 0px ${margins.s};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${margins.s};
`;

const noop = () => {};

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  validateCardNumber(cardNumber) {
    const strippedCardNumber = cardNumber.replace(/ /g, '');
    if (!isCardTypeValid(strippedCardNumber)) {
      this.setState({ cardError: 'les cartes American Express (Amex) ne sont pas supportées' });
    } else if (strippedCardNumber && strippedCardNumber.length > 15 && !isCardNumberValid(strippedCardNumber)) {
      this.setState({ cardError: 'Oups une erreur s\'est glissé. Êtes-vous sûr du numéro ?' });
    } else {
      this.setState({ cardError: null });
    }
  }

  inputChange(field, value) {
    if (field === 'cardNumber') this.validateCardNumber(value);
    this.setState({ [field]: value });
  }

  isValid() {
    return (this.state.expiryDate && this.state.cvc && this.state.cardNumber
      && isCardTypeValid(this.state.cardNumber) && isCardNumberValid(this.state.cardNumber));
  }

  submit() {
    if (!this.isValid()) {
      return this.setState({ formError: true });
    }
    const { expiryDate, cvc, cardNumber, cardId } = this.state;
    const { orderId, customerId } = this.props;
    const card = { expiryDate, cvc, cardNumber, cardId };
    return this.props.submit(card, orderId, customerId);
  }

  renderCreditCard() {
    return (<CreditCard
      onChange={this.inputChange}
      formError={this.state.formError}
      error={this.state.cardError}
      cardNumber={this.state.cardNumber}
      expiryDate={this.state.expiryDate}
      cvc={this.state.cvc}
    />);
  }

  renderError() {
    const { error, errorType } = this.props;
    const message = (errorType && errorMessages[errorType]) || 'Une erreur a eu lieu';
    return (
      <div>
        {error && <ErrorText>{message}</ErrorText>}
        {this.state.formError && <ErrorText>Merci de remplir tous les champs</ErrorText>}
      </div>
    );
  }

  render() {
    const { order, orderId, customerId } = this.props;
    if (!orderId || !customerId) return (<h1>Chargement</h1>);
    return (
      <Container>
        <MenuBar />
        <Content>
          <Background>
            <PaymentContainer>
              <Column>
                {order &&
                  <OrderSummary
                    slots={order.rdv1Slots}
                    deliveryFee={order.deliveryFee}
                    minOrderAmount={order.minOrderAmount}
                    minOrderAmountFee={order.minOrderAmountFee}
                    total={order.total}
                    totalClothes={order.totalClothes}
                    hidePromoCode
                    noPadding
                    goToEstimate={noop}
                  />
                }
                <StyledSubTitle2>Paiement</StyledSubTitle2>
                <Row>
                  <TextContainer>
                    Vous serez débité du montant exact de votre commande {!isMobile() && <br />}
                    après votre premier rendez-vous avec le couturier.
                  </TextContainer>
                </Row>
                {this.renderCreditCard()}
                <ButtonContainer>
                  {this.props.isLoading ?
                    <Button>{'chargement…'}</Button> :
                    <Button onClick={this.submit}>{'valider'}</Button>
                  }
                </ButtonContainer>
                {this.renderError()}
              </Column>
            </PaymentContainer>
          </Background>
        </Content>
        <Footer />
      </Container>
    );
  }
}

PaymentPage.propTypes = {
  order: PropTypes.shape({}),
  submit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  errorType: PropTypes.string,
  customerId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
};

PaymentPage.defaultProps = {
  order: null,
  isLoading: false,
  error: false,
  errorType: null,
};


export default PaymentPage;
